import _ from 'lodash';

import { HEADER_FOOTER_SUB_SECTION_TYPES } from '@core/enums/SectionType';

import Fire from '@root/Fire';

// TODO: I stole this from WordTools, need to make more common?
export function debugObj(label, obj) {
  console.log(`============= ${label}:\n`, JSON.stringify(obj, undefined, 2));
}

export const BART_EDITING_TYPE = {
  PERSIST_CHANGES: 'persist_changes',
  CLEAR_CHANGES: 'clear_changes',
};

export const getEditingTaskById = (section) => {
  if (section.editingTask) {
    const [threadID, taskID] = section.editingTask.split('|');
    return section.deal.raw.taskQueues[threadID][taskID];
  }
  return null;
};

export const clearAIEditingView = async (deal) => {
  const editedSections = _.filter(deal.sections, (section) => section.editingTask);

  for (const section of editedSections) {
    const [threadID, taskID] = section.editingTask.split('|');
    const task = section.deal.raw.taskQueues[threadID][taskID];
    //If the task is to delete the section, we need to remove it from the deal
    if (task?.name === 'delete_section') {
      if (section.isCaption) {
        await Fire.removeCaption(section);
      } else if (
        section.subSectionType === HEADER_FOOTER_SUB_SECTION_TYPES.TWO_COL ||
        section.isTemplateHeaderFooterSubSection
      ) {
        await Fire.removeTwoColHeaderFooter(section);
      } else {
        await Fire.removeSection(section);
      }
    }
    await Fire.toggleAIEditingTask(deal.dealID, section.id, null);
  }
};

export const TASK_STATUS = {
  QUEUED: 'queued',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
};

export const STATUS_ORDER = {
  [TASK_STATUS.PROCESSING]: 0,
  [TASK_STATUS.QUEUED]: 1,
  [TASK_STATUS.COMPLETED]: 2,
  [TASK_STATUS.FAILED]: 3,
  [TASK_STATUS.CANCELLED]: 4,
};

export const ValueTypesExplained = [
  { type: 'string', format: 'string' },
  { type: 'list', format: 'string' },
  { type: 'date', format: 'date' },
  { type: 'state', format: 'US State Abbreviation' },
  { type: 'number', format: 'A integer' },
  { type: 'percent', format: 'Floating point number' },
  { type: 'currency', format: 'Floating point number' },
  {
    type: 'table',
    format:
      'Stringified JSON. Each row is a stringified JSON object. The keys are the column headers, the values are the column values.',
    example: [
      { 'header 1': 'col value 1', 'header 2': 'col value 2', 'header 3': 'col value 3', 'header 4': 'col value 4' },
    ],
  },
  { type: 'multi-select', format: 'multi-select' },
];

export function isReasoningModel(model) {
  return /^o[13]/.test(model);
}

//
// -= Bart Stream Protocol =-
// This protocol enables us to send multiple "channels" of streaming text from the
// Bart backend to the client. For now we use this to send both AI assistant streaming
// response text, as well as error messages. We can extend this system to add other channels
// for things like status, or whatever we like.
// When you add a new channel here, make sure to update bartStreamAction in BartClient.js
// to handle it properly.
export const bartStreamMarkers = Object.freeze({
  TEXT: '|TXT|',
  ERROR: '|ERR|',
  // Add more types if needed, e.g. WARN: '|WARN|' or '|STATUS|' etc
});

export function parseBartStreamText(text) {
  const messages = [];
  let i = 0;

  while (i < text.length) {
    // Expect a valid marker at the current position
    if (text[i] !== '|') {
      throw new Error(`Expected '|' at position ${i}, found '${text[i]}'`);
    }

    // Identify which marker we have
    let matchedType = null;
    let matchedMarker = null;
    for (const [type, marker] of Object.entries(bartStreamMarkers)) {
      if (text.startsWith(marker, i)) {
        matchedType = type;
        matchedMarker = marker;
        break;
      }
    }
    if (!matchedMarker) {
      // If we can't match a full marker here, that's an error
      throw new Error(`Unrecognized marker at position ${i}`);
    }

    // Move past the marker
    i += matchedMarker.length;

    // Gather text until the next valid marker (or end of input)
    const startOfMessage = i;
    while (i < text.length) {
      if (text[i] === '|') {
        // Might be the start of the next marker
        let isNewMarker = false;
        for (const marker of Object.values(bartStreamMarkers)) {
          if (text.startsWith(marker, i)) {
            isNewMarker = true;
            break;
          }
        }
        if (isNewMarker) {
          break;
        }
        // If we see '|' but can't match a full marker, that's an error
        throw new Error(`Unexpected '|' or partial marker at position ${i}`);
      }
      i++;
    }

    // Slice out the message text and store
    messages.push({
      type: matchedType,
      text: text.slice(startOfMessage, i),
    });
  }

  return messages;
}
