import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import Deal from '@core/models/Deal';
import List from '@core/models/List';
import { NUMBER_INDENT } from '@core/models/TypeStyle';
import AIBlockRunner from '@core/utils/AIBlockRunner';

import { Button, Icon, Loader, Popover } from '@components/dmp';

import ColorLabel from '@components/ColorLabel';
import PageBreak from '@components/PageBreak';
import DataSourceBrowser from '@components/deal/DataSourceBrowser';
import ContentSection from '@components/section_types/ContentSection';
import { measure } from '@components/section_types/SectionMeasurer';
import Fire from '@root/Fire';
import { getEditingTaskById } from '@root/bart/BartShared';
import trackEvent from '@utils/EventTracking';

@autoBindMethods
export default class ListSection extends Component {
  // ListSection basically wraps ContentSection with some modified UI elements,
  // so has all the same props that we'll need to pass to child instances
  static propTypes = _.merge({}, _.cloneDeep(ContentSection.propTypes), {
    section: PropTypes.instanceOf(List).isRequired,
  });

  static defaultProps = _.cloneDeep(ContentSection.defaultProps);

  constructor(props) {
    super(props);
    this.childRefs = {};
    this.state = {
      selecting: false,
      reselect: false,
      streaming: false,
      streamingSection: null,
      showingPopover: false,
    };
    this.sectionRefs = {};
    this.refSelf = createRef();

    this.generateRef = createRef();
  }

  componentDidUpdate(prevProps) {
    measure(this);
  }

  async runBlock() {
    const { section, user } = this.props;
    const { aiPrompt, deal } = section;

    await this.setState({ selectedBlockIndex: -1, results: [], error: null });

    // When streaming is on (currently Vinnie blocks only), this callback enables us to pipe chunks of contents through to the front-end for rendering
    // The logic for whether to use streaming and call the callback is internal to AIBlockRunner.runBlock(),
    // so we can just pass it in here either way, and if streaming is not enabled in the AIPrompt config, it doesn't get called.
    // In terms of rendering the streamed content, we're actually creating a proxy (duplicate) Section and ContentSection below to render it;
    // this way we can visualize the interim streamed states without saving them to Firebase (which would trigger a lot of unwanted processing)
    const onChunk = async (chunk, full) => {
      //remove citations - we want to remove citations as we stream as to not confuse the users.
      const content = full.replace(/\【.*?】/g, '');
      const json = _.merge({}, deal.raw.sections[section.id], {
        content,
      });
      const streamingSection = Deal.createSection(json, deal);
      // 1. This callback gets called multiple times, so we're continuously updating state to render the latest section content
      await this.setState({ streaming: true, streamingSection });
    };

    // 2. This triggers the API call (streaming or not), but only resolves only after all chunks are done rendering (streaming)
    // runBlock() also saves the final (complete) response to Firebase, so we don't need to handle that here
    await AIBlockRunner.runBlock(section, onChunk);

    // 3. Once we get here, the full API call has completed AND saved (streaming or not), so if streaming was on,
    // we can now reset it, which will disable/hide the proxy ContentSection
    // And the real ContentSection with the saved content will be showing the identical completed content
    await this.setState({ streaming: false, streamingSection: null });

    const eventData = {
      serviceType: `${_.upperFirst(aiPrompt.type)} AI Block Preview`, //should eventually be enumerated somewhere for additional types
      docID: deal.dealID,
      user: user.email,
      teamID: deal.team,
      engine: aiPrompt.engine.key,
      isTemplate: deal.isTemplate,
      template: deal.info.sourceTemplate,
    };
    await trackEvent('ListSectionSummarize', eventData);
    await this.setState({ selecting: false, reselect: false });
  }

  async stopRunningHack() {
    const { section } = this.props;
    await AIBlockRunner.stopRunningHack(section);
  }

  get hasError() {
    return !_.isNil(this.props.section.aiPrompt?.lastError);
  }

  get errorText() {
    return this.hasError ? this.props.section.aiPrompt?.lastError : '';
  }

  showPopover() {
    this.setState({ showingPopover: true });
  }

  hidePopover() {
    this.setState({ showingPopover: false });
  }

  handleGenerate() {
    if (this.hasError) {
      this.showPopover();
    } else {
      this.runBlock();
    }
  }

  handleTryAgain() {
    this.hidePopover();
    this.runBlock();
  }

  onCreate(newSectionID) {
    const newSection = _.get(this.childRefs[newSectionID], 'current');
    if (newSection) newSection.focus();
  }

  async addItem(section, atIndex) {
    let newSectionID = null;
    newSectionID = await Fire.addItemSection(section, atIndex);
    this.onCreate(newSectionID);
  }

  async handleDSItems(ds, items) {
    const { section: list, user } = this.props;

    if (list) {
      await Fire.clearList(list);
      const master = list || list.appendix;
      if (master) {
        Fire.addActivity(master, user, DealAction.CLEAR_LIST);
      }
    }

    const sections = _.map(items, (item) => list.generateItem(item));
    if (sections.length > 0) {
      await Fire.addItemBatch(list, sections, null, true);
    }
    await this.setState({ selecting: false, reselect: false });
  }

  handleKey(e, item) {
    switch (e.key) {
      case 'Enter':
        if (e.shiftKey) return false;
        // Only handle enter as adding items if it's a list
        if (_.get(item, 'list.subType') === 'LIST') {
          this.addItem(item.parent, item.sibs.indexOf(item) + 1);
          return true;
        } else {
          return false;
        }
      case 'Tab':
        Fire.moveSection(item, e.shiftKey ? 'left' : 'right');
        return true;
      default:
        return false;
    }
  }

  renderItem(item, idx) {
    const { section: list } = this.props;

    if (!this.childRefs[item.id]) this.childRefs[item.id] = createRef();
    const props = _.merge({}, this.props, {
      section: item,
      editableTitle: list.titles,
      sourceMode: true,
      editable: list.can('edit'),
      hideMenu: !list.can('edit'),
    });

    const task = getEditingTaskById(item);
    if (task) {
      if (['insert_new_section', 'delete_section', 'update_section_content'].includes(task.name)) {
        //These two properties trigger the ai diff view
        props.showClean = false;
        props.compareVersion =
          task.name === 'insert_new_section' || task.name === 'delete_section'
            ? item.currentVersion
            : item.previousVersion;
      }
    }

    return (
      <ContentSection
        {...props}
        key={idx}
        ref={this.childRefs[item.id]}
        onCreate={this.onCreate}
        reselectListContent={() => this.setState({ selecting: true, reselect: true })}
        titleKeyHandler={this.handleKey}
        bodyKeyHandler={this.handleKey}
        setQueueFocus={this.onCreate}
      />
    );
  }

  // This is a bit annoying but we need to manually align the "Add item" link style with the list's first-level children
  // That comes from the theme (instead of css) so we need to manually build it here too
  // Also we apply bottom section margin to the button, which is necessary in the event that the list has no items
  get addItemStyle() {
    const { section: list, overviewMode } = this.props;

    const style = {};
    let indent = overviewMode ? 0 : _.get(list, 'webLayout.paddingLeft', 0);

    if (overviewMode) {
      if (list.parent.showOrder) indent += NUMBER_INDENT;
    } else {
      // Add padding if the list itself is numbered
      if (list.showOrder) indent += NUMBER_INDENT;
      // And add more if the list is configured to indent its children (defaults to true)
      if (list.indent) indent += NUMBER_INDENT;
    }

    style.marginLeft = indent;

    // Somewhat of a hack, but empty lists will still render an empty ContentSection
    // But we want the "Add item" link to appear in line with that even though it's technically rendered by this component
    // so setting a negative top on it pulls it up
    if (list.isNumberPlaceholder && !list.isRepeater) {
      style.top = -list.webLayout.marginBottom;
    } else {
      style.marginBottom = list.webLayout.marginBottom;
    }

    return style;
  }

  get isEditing() {
    const focused = _.find(this.childRefs, (ref) => !!_.get(ref, 'current.focused'));
    return !!focused;
  }

  // Whether to show top-level list content (title/body)
  get showListContent() {
    const { section: list, overviewMode } = this.props;

    // Repeater content is actually a template for its children so never show
    if (overviewMode || list.isRepeater) return false;

    // If there is no content, and it's not a placeholder, don't show
    if (!list.currentVersion.hasContent && !list.isNumberPlaceholder) return false;

    // Timeline sections are rendered via 3rd party library (not in react)
    if (list.isTimeline) return false;

    // If we get here, we've got real content to show
    return true;
  }

  render() {
    const { section: list, overviewMode } = this.props;
    const { selecting, reselect, streaming, streamingSection } = this.state;
    const dsName = _.get(list, 'dataSource.displayName', '');

    // Repeaters with no data source setup are invalid -- render nothing
    if (list.isRepeater && !list.dataSource) return null;

    // Don't show an empty placeholder section for lists that aren't editable by current user
    if (list.isNumberPlaceholder && !list.can('edit')) return null;

    // WIP, still determining exact scenarios for when an AI Block (or Timeline?) should render children
    const aiRenderList = list.isAI && _.get(list, 'aiPrompt.responseType') === 'list';

    // Don't render Timeline sections in Flow
    // TODO: determine if this is needed here, or whether we remove Timeline sections from source (buildSource)
    // (the latter would be cleaner/preferable, but then we'll need to figure out where to configure them in Draft)
    if (list.isTimeline) return null;

    const canReviseAI = list.can('reviseAI');

    const defautlActionName = list.aiPrompt?.actionName || list.actionName;
    const actionName = list.hasBodyText ? defautlActionName?.replace('Generate', 'Re-generate') : defautlActionName;

    const showAIActionButton = list.isAIList ? list.children.length <= 0 : !list.hasBodyText;

    const isRegenerating = list.topLevelAIBlock.isAIList
      ? list.topLevelAIBlock.children.length > 0
      : list.topLevelAIBlock.hasBodyText;

    const assistantIsRunning = !!_.find(list.deal.sections, ({ aiPrompt }) => {
      return aiPrompt && aiPrompt.isRunning && aiPrompt.isAssistant;
    });

    const disableBlock = assistantIsRunning && list?.aiPrompt?.isAssistant;

    return (
      <div className="list-section" data-cy="list-section" ref={this.refSelf}>
        {/*
          First render the parent (list-level) content, if there is any
          In Flow, the parent itself is uneditable/uncommentable
          REPEATERs have no titles and use the body for the template, so don't render that!
         */}
        {this.showListContent && (
          <ContentSection
            {...this.props}
            sourceMode
            indent
            noActivity={!canReviseAI}
            readonly={!canReviseAI || list.aiPrompt?.isRunning}
            hideMenu={!canReviseAI || list.aiPrompt?.isRunning}
            updateListStream={(streaming, streamingSection) => this.setState({ streaming, streamingSection })}
          />
        )}

        {/*
          This is the "proxy" ContentSection mentioned above in runBlock()
          It's only used to visualize interim content as API.assist() streams it through to the front-end
        */}
        {streaming && (
          <ContentSection sourceMode readonly hideMenu noReplace activityMode="none" section={streamingSection} />
        )}

        {/*
          Now render the list's children. Note, list.items "flattens" ALL children even if the list is hierarchical
          So this will render all of them at appropriate indentLevels
        */}
        {(!list.isAI || aiRenderList) && (
          <div className="items-container" data-cy="items-container">
            {list.items.length === 0 && list.isRepeater && list.pageBreak && (
              <div className="source-section page-break">
                <PageBreak section={list} />
              </div>
            )}
            {_.map(list.items, (item, idx) => {
              if (list.isRepeater) {
                return (
                  <>
                    {list.isRepeater && list.pageBreak && (
                      <div className="source-section page-break">
                        <PageBreak section={item} />
                      </div>
                    )}
                    {this.renderItem(item, idx)}
                  </>
                );
              } else {
                return this.renderItem(item, idx);
              }
            })}
          </div>
        )}
        {list.can('add') && !this.isEditing && !['REPEATER', 'AI', 'TIMELINE'].includes(list.subType) && (
          <div
            className="add-item"
            data-cy="add-item"
            onClick={() => this.addItem(list, list.children.length)}
            style={this.addItemStyle}
          >
            <span>
              {list.subType === 'LIST' ? 'Add item' : 'Enter content'} {list.isEmpty && list.required && '(required)'}
            </span>
            {list.isEmpty && list.required && !overviewMode && !list.isNumberPlaceholder && (
              <div className="marker">
                <ColorLabel status="todo" right label=" " />
              </div>
            )}
          </div>
        )}
        {list.can('generate') && !this.isEditing && list.isEmpty && (
          <div
            className="browse-items"
            data-cy="generate"
            onClick={() => this.setState({ selecting: true })}
            style={this.addItemStyle}
          >
            Select {dsName}
          </div>
        )}
        {list.can('ai') && showAIActionButton && !list.aiPrompt?.isRunning && (
          <>
            <div
              ref={this.generateRef}
              className={cx('add-item add-ai-block', { 'ai-error': this.hasError, 'disable-action': disableBlock })}
              data-cy="summarize"
              onClick={this.handleGenerate}
              style={this.addItemStyle}
            >
              <Icon name="aiAuto" />
              {actionName}
            </div>

            {this.hasError && (
              <Overlay
                show={this.state.showingPopover}
                target={this.generateRef.current}
                placement="bottom"
                container={this}
                onHide={this.hidePopover}
                rootClose
              >
                <Popover
                  className="popover-variable-editor"
                  title="AI Generation Error"
                  closeBtn={true}
                  onHide={this.hidePopover}
                  id="ai-error-popover"
                  data-cy="ai-error-popover"
                >
                  <div>
                    {this.errorText}
                    <div className="actions">
                      <Button size="small" onClick={this.handleTryAgain}>
                        Try Again
                      </Button>
                    </div>
                  </div>
                </Popover>
              </Overlay>
            )}
          </>
        )}
        {list.aiPrompt?.isRunning && (
          <div onDoubleClick={this.stopRunningHack} className="ai-block-loader">
            <Loader /> {isRegenerating ? 'Re-generating' : 'Generating'} AI {list.isAIList ? 'List' : 'Block'}, please
            wait...
          </div>
        )}
        {selecting && (
          <DataSourceBrowser
            deal={list.deal}
            show={true}
            multiselect
            variable={list.dataSource}
            onHide={() => this.setState({ selecting: false, reselect: false })}
            onSelect={this.handleDSItems}
            headlineAction={reselect ? 'Re-select' : null}
          />
        )}
      </div>
    );
  }
}
