import API from '@root/ApiClient';

import { parseBartStreamText } from './BartShared';

export async function bartAction(action, args) {
  const res = await API.call('bart', {
    action,
    ...args,
  });
  if (!res.success) {
    throw new Error(res.details);
  }
  return res;
}

export async function bartStreamAction(action, args, { onText, onError }) {
  await API.stream(
    'bartStream',
    {
      action,
      ...args,
    },
    (chunk) => {
      for (const msg of parseBartStreamText(chunk)) {
        if (msg.type === 'ERROR') {
          onError(msg.text);
        } else if (msg.type === 'TEXT') {
          onText(msg.text);
        } else {
          throw new Error('Unrecognized bart stream message type: ' + JSON.stringify(msg));
        }
      }
    }
  );
}
