import React, { useState } from 'react';

import { Overlay, Popover, Radio } from 'react-bootstrap';

import { Button } from '@components/dmp';

import Fire from '@root/Fire';

import { BART_EDITING_TYPE } from './BartShared';

export default function BartChat({ show, close, target, user, container }) {
  const [bartConfig, setBartConfig] = useState(user?.bartEditingConfig || {});
  const timeout = (bartConfig.timeout ?? 2).toString();

  const handleConfigChange = (newConfig) => {
    if (newConfig.timeout !== undefined) {
      newConfig.timeout = newConfig.timeout === '' ? 0 : Number(newConfig.timeout);
    }
    setBartConfig(newConfig);
  };

  const handleSave = () => {
    Fire.saveUserBartEditingConfig(user.id, bartConfig);
    close();
  };

  const radioStyle = {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '1.8',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  };

  return (
    <Overlay onHide={close} placement="bottom" rootClose show={show} target={target} container={container}>
      <Popover
        id="bart-editing-config-pop"
        title="AI change highlighting"
        className="popover-bart-editing-config"
        data-cy="popover-bart-editing-config"
        onHide={close}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>
          <div>
            <Radio
              checked={bartConfig.type === BART_EDITING_TYPE.PERSIST_CHANGES}
              name="isTeamFilter"
              onChange={() => handleConfigChange({ type: BART_EDITING_TYPE.PERSIST_CHANGES })}
              value={true}
              style={radioStyle}
            >
              Persist until cleared or next AI change
            </Radio>
            <Radio
              checked={bartConfig.type === BART_EDITING_TYPE.CLEAR_CHANGES}
              name="isTeamFilter"
              onChange={() => handleConfigChange({ type: BART_EDITING_TYPE.CLEAR_CHANGES, timeout })}
              value={true}
              style={radioStyle}
            >
              Clear after
              <input
                value={timeout}
                style={{
                  width: '30px',
                  height: '20px',
                  padding: '2px 4px',
                  fontSize: '12px',
                  borderRadius: '5px',
                  margin: '0 4px',
                }}
                onChange={(event) =>
                  handleConfigChange({ type: BART_EDITING_TYPE.CLEAR_CHANGES, timeout: event.target.value })
                }
                type="text"
                pattern="[0-9]*"
              />
              seconds
            </Radio>
          </div>
          <Button size="small" dmpStyle="primary" style={{ marginLeft: 'auto' }} onClick={handleSave}>
            Save
          </Button>
        </div>
      </Popover>
    </Overlay>
  );
}
