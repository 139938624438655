import React from 'react';
import { useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import { atom, useRecoilState } from 'recoil';

import { Icon, Loader } from '@components/dmp';

import API from '@root/ApiClient';

import { ResizeHandle } from './BartResizer';
import { TASK_STATUS } from './BartShared';

const tabState = atom({
  key: 'bart-debug-tab',
  default: 'TASKS',
});

export function BartDebug({ threadID, open, width, handleResize, taskQueues, setAIEditingSection }) {
  const [tab] = useRecoilState(tabState);

  return (
    <div
      style={{
        position: 'fixed',
        left: open ? 0 : -width,
        top: 0,
        height: '100%',
        width: width,
        transition: 'left 0.3s ease',
        backgroundColor: 'black',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TitleBar />

      {tab === 'MESSAGES' && <Messages threadID={threadID} open={open} />}
      {tab === 'RUNS' && <Runs threadID={threadID} open={open} />}
      {tab === 'TASKS' && (
        <Tasks taskQueues={taskQueues} threadID={threadID} setAIEditingSection={setAIEditingSection} />
      )}

      <ResizeHandle
        onResize={handleResize}
        style={{ backgroundColor: 'rgba(255,255,255,20%)', width: 10, height: 40, cursor: 'ew-resize' }}
      />
    </div>
  );
}

//----------------------------------------------------------------------------------------
// BartDebug Subcomponents
//----------------------------------------------------------------------------------------
function TitleBar() {
  return (
    <div
      style={{
        fontWeight: 'bold',
        fontSize: 'larger',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        flexShrink: 0,
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        marginRight: 10,
        borderBottom: '1px solid red',
      }}
    >
      <div>Bart Debug</div>
      <div style={{ flexGrow: 1 }} />
      <Tabs />
    </div>
  );
}

function Tabs() {
  const [tab, setTab] = useRecoilState(tabState);

  const makeStyle = (tabKey) => {
    if (tab === tabKey) {
      return { color: 'hsl(210, 100%, 65%', textDecoration: 'underline' };
    } else {
      return { color: 'hsl(210, 80%, 50%' };
    }
  };

  const NavLink = ({ label, tabKey }) => {
    return (
      <a
        style={makeStyle(tabKey)}
        onClick={() => {
          setTab(tabKey);
        }}
      >
        {label}
      </a>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 15, marginRight: 10 }}>
      <NavLink label="Messages" tabKey={'MESSAGES'} />
      <NavLink label="Runs" tabKey={'RUNS'} />
      <NavLink label="Tasks" tabKey={'TASKS'} />
    </div>
  );
}

function Runs({ threadID, open }) {
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadRuns = async () => {
    setLoading(true);
    const res = await API.call('bart', {
      action: 'LIST_RUNS',
      threadID,
    });

    setRuns(res.runs);
    setLoading(false);
  };

  useEffect(() => {
    if (!open) return;
    loadRuns();
  }, [open]);

  return (
    <div style={{ overflow: 'auto', flexGrow: 1, padding: '10px' }}>
      {loading && <div>Loading...</div>}
      {!loading && runs.length === 0 && <div>No runs found</div>}
      {!loading &&
        runs.length > 0 &&
        _.map(_.orderBy(runs, ['created_at'], ['desc']), (run) => <RunRow key={run.id} run={run} />)}
    </div>
  );
}

function RunRow({ run }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 5,
          marginLeft: 10,
          marginRight: 10,
          marginTop: 5,
        }}
      >
        <Icon name="included" dark={true} />
        <span>{run.id}</span>
        <div style={{ flexGrow: 1 }} />
        {!expanded ? (
          <Icon name="chevronUp" dark={true} onClick={() => setExpanded(true)} />
        ) : (
          <Icon name="chevronDown" dark={true} onClick={() => setExpanded(false)} />
        )}
      </div>
      {expanded && (
        <div style={{ marginLeft: 30, fontSize: '12px' }}>
          <ul>
            <li>
              <b>Created At:</b> {new Date(run.created_at * 1000).toLocaleString()}
            </li>
            <li>
              <b>Run Time:</b>{' '}
              {(() => {
                const end_time = run.completed_at || run.failed_at || run.expired_at;
                const diffSec = run.status === 'expired' ? 600 : end_time - run.created_at;
                if (diffSec < 60) {
                  return `${diffSec} seconds`;
                }
                const minutes = Math.floor(diffSec / 60);
                const seconds = diffSec % 60;
                return `${minutes}m ${seconds}s`;
              })()}
            </li>
            <li>
              <b>Status:</b> {run.status}
            </li>
            <li>
              <b>Prompt Tokens:</b> {run.usage?.prompt_tokens}
            </li>
            <li>
              <b>Completion Tokens:</b> {run.usage?.completion_tokens}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

function Tasks({ taskQueues, threadID, setAIEditingSection }) {
  const tasks = taskQueues?.[threadID];
  const sortedTasks = _.orderBy(tasks, ['sortStamp'], ['asc']);
  const groupedTasks = _.groupBy(sortedTasks, 'runID');

  if (!taskQueues || !tasks) {
    return <div style={{ padding: '10px' }}>No tasks found</div>;
  }

  return (
    <div style={{ overflow: 'auto', flexGrow: 1, padding: '10px' }}>
      {_.map(groupedTasks, (value, runID) => (
        <TaskGroup key={runID} runID={runID} tasks={value} setAIEditingSection={setAIEditingSection} />
      ))}
    </div>
  );
}

function TaskGroup({ runID, tasks, setAIEditingSection }) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: 5,
          marginLeft: 10,
          marginRight: 10,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <b>{runID}</b>
        <div style={{ flexGrow: 1 }} />
        {!expanded ? (
          <Icon name="chevronUp" dark={true} onClick={() => setExpanded(true)} />
        ) : (
          <Icon name="chevronDown" dark={true} onClick={() => setExpanded(false)} />
        )}
      </div>
      {expanded &&
        _.map(tasks, (task) => <TaskRow key={task.id} task={task} setAIEditingSection={setAIEditingSection} />)}
    </div>
  );
}

function TaskRow({ task, setAIEditingSection }) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (task.status === TASK_STATUS.PROCESSING && task.properties?.sectionID) {
      setAIEditingSection(task.properties.sectionID);
    }
  }, [task.status]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 5,
          marginLeft: 10,
          marginRight: 10,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        {statusIcon({ status: task.status })}{' '}
        <span onClick={() => setAIEditingSection(task.properties?.sectionID)} style={{ cursor: 'pointer' }}>
          {task.name}
        </span>
        <div style={{ flexGrow: 1 }} />
        {!expanded ? (
          <Icon name="chevronUp" dark={true} onClick={() => setExpanded(true)} />
        ) : (
          <Icon name="chevronDown" dark={true} onClick={() => setExpanded(false)} />
        )}
      </div>
      {expanded && (
        <div style={{ marginLeft: 30, fontSize: '12px' }}>
          <ul>
            <li>
              <b>Date:</b> {new Date(task.timeStamp).toLocaleString()}
            </li>
            <li>
              <b>Task ID:</b> {task.id}
            </li>
            <li>
              <b>Run ID:</b> {task.runID}
            </li>
            <li>
              <b>Status:</b> {task.status}
            </li>
            {task.status === TASK_STATUS.FAILED && (
              <li>
                <b>Error:</b> {task.failureMessage}
              </li>
            )}
            {task.name !== 'variable_actions' &&
              _.map(task.properties, (value, key) => {
                if (key === 'children' || key === 'isNewParent' || key === 'subTasks') return;
                return (
                  <li key={key}>
                    <b>{_.upperFirst(key)}:</b> {value}
                  </li>
                );
              })}
            {task.name === 'insert_new_section' && task.newSectionID && (
              <li>
                <b>newSectionID:</b> {task.newSectionID}
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

function statusIcon({ status }) {
  switch (status) {
    case 'queued':
      return <Icon name="audit" dark={true} />;
    case 'processing':
      return <Loader />;
    case 'completed':
      return <Icon name="green" />;
    case 'failed':
      return <Icon name="red" />;
    case 'cancelled':
      return <Icon name="yellow" />;
    default:
      return <Icon name="yellow" />;
  }
}

function Messages({ threadID, open }) {
  const containerRef = useRef(null);

  const [messages, setMessages] = useState([]);

  const loadMessages = async () => {
    const res = await API.call('bart', {
      action: 'FETCH_THREAD_MESSAGES',
      threadID,
    });
    // TODO: error handling!

    setMessages(res.messages);
  };

  useEffect(() => {
    if (!open) return;
    loadMessages();
  }, [open]);

  useEffect(() => {
    const el = containerRef.current;
    if (!el) return;
    el.scrollTop = el.scrollHeight;
  }, [messages]);

  return (
    <>
      <div ref={containerRef} style={{ overflow: 'auto', flexGrow: 1, padding: '10px' }}>
        {messages.map(({ id, created_at, role, content }) => {
          const text = content?.[0]?.text?.value;
          if (!text) return undefined;

          const displayRole = role === 'user' ? 'User' : 'Bart';
          const roleColor = role === 'user' ? '#61afef' : '#c678dd';
          const timestamp = new Date(created_at * 1000).toLocaleString();

          return (
            <div
              key={id}
              style={{
                borderLeft: '3px solid',
                borderLeftColor: roleColor,
                paddingLeft: '10px',
                marginBottom: '16px',
                fontSize: '11px',
              }}
            >
              <div style={{ marginBottom: '4px' }}>
                <span style={{ fontSize: '13px', fontWeight: 'bold', color: roleColor }}>{displayRole}</span> -{' '}
                <span style={{ color: 'hsl(109, 100.00%, 81.40%)' }}>{timestamp}</span>
              </div>
              <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
            </div>
          );
        })}
      </div>
      <div style={{ color: 'black', flexShrink: 0, margin: 5 }}>
        <button
          onClick={() => {
            loadMessages();
          }}
        >
          Reload
        </button>
      </div>
    </>
  );
}
