import React, { useEffect, useState } from 'react';
import { useRef } from 'react';

import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import { useLocation } from 'react-router-dom';

import { Switch } from '@components/dmp';
import Button from '@components/dmp/Button';
import ButtonIcon from '@components/dmp/ButtonIcon';

import { bartAction, bartStreamAction } from './BartClient';
import { BartDebug } from './BartDebug';
import { ResizeHandle, useResizer } from './BartResizer';
import { isReasoningModel } from './BartShared';

const loadingState = atom({ key: 'bart-chat-loading', default: false });
const errorState = atom({ key: 'bart-chat-error', default: '' });
const messagesState = atom({ key: 'bart-chat-messages', default: [] });
const modelState = atom({ key: 'bart-chat-model', default: 'gpt-4o' });
const effortState = atom({ key: 'bart-chat-effort', default: 'medium' });
const bartEnabledState = atom({ key: 'bart-chat-enabled', default: true });

export default function BartChat({ user, deal, setAIEditingSection }) {
  const { dealID } = deal;
  const { id: uid } = user;

  const [debugOpen, setDebugOpen] = useState(false);

  const setError = useSetRecoilState(errorState);
  const [chatMessages, setChatMessages] = useRecoilState(messagesState);
  const [model, setModel] = useRecoilState(modelState);
  const [effort] = useRecoilState(effortState);
  const [isBartEnabled, setBartEnabled] = useRecoilState(bartEnabledState);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hideFromUrl = queryParams.get('hideBart') === 'true';

  // Check both URL param and Cypress environment to determine if BART should be enabled
  useEffect(() => {
    const isCypress = window.Cypress;
    if (hideFromUrl || (isCypress && window.Cypress.env('hideBart'))) {
      setBartEnabled(false);
    }
  }, []); // Only run once at mount

  const useVectorStore = user.threads && user.threads[deal.dealID] ? user.threads[deal.dealID].useVectorStore : false;
  const threadID = user.threads && user.threads[deal.dealID] ? user.threads[deal.dealID].threadID : null;

  useHotkey({ key: 'd', onTriggered: () => setDebugOpen((lastVal) => !lastVal) });

  const [dimensions, handleResize] = useResizer({
    defaultDimensions: { width: 900, height: 400 },
    storageKey: 'bart.window.dimensions',
  });

  const [debugDimensions, debugHandleResize] = useResizer({
    defaultDimensions: { width: 400 },
    storageKey: 'bart.debugWindow.dimensions',
  });

  useEffect(() => {
    (async () => {
      if (!threadID) {
        await bartAction('START_THREAD', { dealID, uid, useVectorStore });
      } else {
        const { messages } = await bartAction('FETCH_THREAD_MESSAGES', { threadID });
        const msgs = _.compact(
          _.map(messages, ({ content, role }) => {
            const contentValue = content[0].text.value;
            if (!contentValue.includes('Document:') && !contentValue.includes('User:'))
              return { role, content: contentValue };
          })
        );
        setChatMessages(msgs);
      }

      const { model: assistantModel } = await bartAction('GET_MODEL');
      setModel(assistantModel);
    })();
  }, []);

  const sendUserInput = useBartWork(async (text) => {
    setChatMessages((prev) => [
      ...prev,
      {
        role: 'user',
        content: text,
      },
    ]);

    await bartStreamAction(
      'RUN_THREAD',
      {
        threadID,
        message: text,
        dealID,
        uid,
        useVectorStore,
        model,
        effort,
      },
      {
        onText: (txt) => {
          setChatMessages((prev) => {
            let newMessages = [...prev];
            if (newMessages.length === 0 || newMessages[newMessages.length - 1].role !== 'assistant') {
              newMessages.push({ role: 'assistant', content: txt });
            } else {
              const lastMsg = newMessages[newMessages.length - 1];
              const newLastMsg = {
                role: lastMsg.role,
                content: lastMsg.content + txt,
              };
              newMessages = [...newMessages.slice(0, -1), newLastMsg];
            }
            return newMessages;
          });
        },
        onError: (err) => {
          console.error(err);
          setError(err);
        },
      }
    );
  });

  // If BART is disabled, don't render anything
  if (!isBartEnabled) return null;

  return (
    <div
      style={{
        zIndex: 99999,
        position: 'fixed',
        left: debugOpen ? debugDimensions.width : 0,
        bottom: 0,
        width: dimensions.width,
        height: dimensions.height,
        transition: 'left 0.3s ease',
        padding: 10,
        display: 'flex',
        backgroundColor: 'skyblue',
        flexDirection: 'column',
      }}
    >
      <TitleBar />
      <ToolBar dealID={dealID} uid={uid} threadID={threadID} useVectorStore={useVectorStore} />
      <Conversation messages={chatMessages} />
      <ErrorDisplay />
      <UserInput onSend={sendUserInput} />

      <ResizeHandle onResize={handleResize} />
      <BartDebug
        threadID={threadID}
        open={debugOpen}
        width={debugDimensions.width}
        handleResize={debugHandleResize}
        taskQueues={deal.taskQueues}
        setAIEditingSection={setAIEditingSection}
      />
    </div>
  );
}

//----------------------------------------------------------------------------------------
// BartChat Subcomponents
//----------------------------------------------------------------------------------------
function TitleBar() {
  return (
    <div style={{ fontWeight: 'bold', fontSize: 'larger', whiteSpace: 'nowrap', overflowX: 'hidden', flexShrink: 0 }}>
      Bart Chat - 🚧 ⚠️FOR INTERNAL USE ONLY: DO NOT DEMO⚠️ 🚧
    </div>
  );
}

function ToolBar({ dealID, uid, threadID, useVectorStore }) {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [loading] = useRecoilState(loadingState);
  const setChatMessages = useSetRecoilState(messagesState);

  const clearThread = useBartWork(async () => {
    await bartAction('CLEAR_THREAD', { dealID, threadID });
    setChatMessages([]);
  });

  const toggleThread = useBartWork(async () => {
    await bartAction('DELETE_THREAD', { threadID, dealID, uid });
    await bartAction('START_THREAD', { dealID, uid, useVectorStore: !useVectorStore });
    setChatMessages([]);
  });

  return (
    <div
      style={{
        backgroundColor: 'hsl(210, 100%, 70%)',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
        <ModelPicker />
        <Button size="small" onClick={clearThread} disabled={loading || !threadID}>
          Clear Thread
        </Button>
        <div style={{ flexGrow: 1 }}></div>
        <ButtonIcon icon="settingsCog" onClick={() => setShowAdvanced((prev) => !prev)}></ButtonIcon>
      </div>
      {showAdvanced && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
          <Switch checked={useVectorStore} onChange={toggleThread} size="small" disabled={loading}>
            Use Vector Store (file search)
          </Switch>
        </div>
      )}
    </div>
  );
}

function ModelPicker() {
  const [models, setModels] = useState([]);
  const [loading] = useRecoilState(loadingState);
  const [model, setModel] = useRecoilState(modelState);
  const [effort, setEffort] = useRecoilState(effortState);

  const efforts = ['low', 'medium', 'high'];

  useEffect(() => {
    (async () => {
      const { models: fetchedModels } = await bartAction('LIST_MODELS');
      setModels(fetchedModels);
    })();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '3px' }}>
      <select value={model} disabled={loading} onChange={(e) => setModel(e.target.value)}>
        {models.map((x) => (
          <option key={x}>{x}</option>
        ))}
      </select>
      {isReasoningModel(model) && (
        <select value={effort} disabled={loading} onChange={(e) => setEffort(e.target.value)}>
          {efforts.map((x) => (
            <option key={x}>{x}</option>
          ))}
        </select>
      )}
    </div>
  );
}

function Conversation({ messages }) {
  const scrollRef = useRef(null);

  // TODO: make this a reusable hook, so we can auto-scroll anything
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      ref={scrollRef}
      style={{
        overflowY: 'auto',
        fontSize: '11pt',
        flexGrow: '1',
        backgroundColor: 'hsl(0, 0%, 12%)',
        color: 'hsl(0, 0%, 90%)',
      }}
    >
      {messages.map((message, i) => {
        switch (message.role) {
          case 'user':
            return <UserMessageBox key={i} message={message} />;
          case 'assistant':
            return <AssistantMessageBox key={i} message={message} />;
          case 'tool':
            return <ToolMessageBox key={i} message={message} />; //TODO: probably only show this in debug
          default:
            return <div key={i}>Unknown message role: {JSON.stringify(message, undefined, 2)}!</div>;
        }
      })}
    </div>
  );
}

function UserMessageBox({ message }) {
  if (message.content.startsWith('Document: \n{')) return null;

  return (
    <div
      style={{
        textAlign: 'right',
        backgroundColor: 'hsl(210, 50%, 25%)',
        margin: '10px',
        padding: '5px 10px 5px 10px',
        borderRadius: '5px',
      }}
    >
      <strong>You:</strong> {message.content}
    </div>
  );
}

function AssistantMessageBox({ message }) {
  return (
    <div
      style={{
        textAlign: 'left',
        backgroundColor: 'hsl(0, 0%, 20%)',
        margin: '10px',
        padding: '5px 10px 5px 10px',
        borderRadius: '5px',
      }}
    >
      <strong>Bart:</strong> {message.content}
    </div>
  );
}

// TODO: Not in use, but just keeping as example of handy debug mode we could implement later,
// or use in BartDebug... had the idea that these MessageBox components should be shared between
// debug and here, with maybe just different styling, and showing different content possibly?
function ToolMessageBox({ message }) {
  return (
    <div
      style={{
        textAlign: 'left',
        backgroundColor: 'hsl(150, 50%, 20%)',
        margin: '10px',
        padding: '5px 10px 5px 10px',
        borderRadius: '5px',
      }}
    >
      <strong>DEBUG: Tool Response:</strong> {message.content}
    </div>
  );
}

function UserInput({ onSend }) {
  const [text, setText] = useState('');
  const [loading] = useRecoilState(loadingState);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!loading && text.length > 0) {
        onSend(text);
        setText('');
      }
    }
  };

  return (
    <textarea
      placeholder={loading ? 'Thinking...' : 'Message Bart - ⚠️WARNING⚠️ DO NOT SEND SENSITIVE DATA!'}
      value={text}
      onChange={(e) => setText(e.target.value)}
      onKeyDown={handleKeyDown}
      style={{ backgroundColor: loading ? 'hsl(0,0%,78%)' : 'white', flexShrink: 0 }}
    ></textarea>
  );
}

function ErrorDisplay() {
  const [error, setError] = useRecoilState(errorState);
  if (error === '') return null;

  const textColor = 'hsl(0, 100%, 70%)';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'hsl(0, 60%, 25%)',
        padding: '5px',
      }}
    >
      <div style={{ color: textColor, fontWeight: 'bold', marginRight: '15px' }}>ERROR:</div>
      <div style={{ color: textColor }}>{error}</div>
      <div style={{ flexGrow: 1 }}></div>
      <button
        onClick={() => {
          setError('');
        }}
      >
        x
      </button>
    </div>
  );
}

//----------------------------------------------------------------------------------------
// Bart Hooks
//----------------------------------------------------------------------------------------
function useBartWork(cb) {
  const setLoading = useSetRecoilState(loadingState);
  const setError = useSetRecoilState(errorState);

  return async (...args) => {
    try {
      setLoading(true);
      await cb(...args);
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
}

//----------------------------------------------------------------------------------------
// General Hooks (not Bart-specific)
//----------------------------------------------------------------------------------------
function useHotkey({ key, onTriggered }) {
  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === key) {
      event.preventDefault();
      onTriggered();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
