import React from 'react';

import PropTypes from 'prop-types';

import { BASE_ID } from '@core/models/Version';

import { Icon } from '@components/dmp';

const VersionListItem = ({ section, version, selected }) => {
  const du = section.deal.getUserByID(version.user);

  let author =
    version.id === BASE_ID ? 'Original' : version.getDisplayName(du, _.get(section.deal, 'currentDealUser.uid'));
  if (version == section.currentVersion) author = 'Current - ' + author;

  return (
    <div className={`version${selected ? ' selected' : ''}`}>
      <div className="version-label">
        <span className="version-number">{`V${section.versions.indexOf(version) + 1}`}</span>
      </div>
      <div className="info">
        <div className="author" style={{ whiteSpace: 'nowrap' }}>
          {du?.isAI && (
            <Icon
              name="aiAuto"
              style={{
                marginRight: 2,
                marginTop: -2,
                verticalAlign: 'middle',
                position: 'relative',
                fill: 'rgb(153, 153, 153)',
              }}
            />
          )}
          {author}
        </div>
        {version.date && <div className="timestamp">{version.displayDate}</div>}
      </div>
    </div>
  );
};

VersionListItem.propTypes = {
  section: PropTypes.object,
  version: PropTypes.object,
  selected: PropTypes.bool,
};

export default VersionListItem;
